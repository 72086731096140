<template lang="pug">
.manage-filters
	.filter-title
		p.name {{ filter.title }}
		button.remove-btn(@click="$emit('removeFilter')") 
			CrossIcon
	template(v-if="filter.bars.length > 0")
		.bar(v-for="(bar, i) in filter.bars", :key="i")
			button.remove-btn(@click="$emit('removeFilterBar', bar)") -
			p.name {{ bar.title }}
	.no-bars(v-else)
		p No bars applied
	button.add-bar-btn(v-if="!showBarInput", @click="addBar") 
		PlusIcon.icon
		| Add Another Bar
	input.add-bar-input(
		v-else,
		v-model="newBarName",
		ref="newBarInput",
		@blur="showBarInput = false",
		@keyup.enter="submitBar"
	)
</template>

<script>
import CrossIcon from "@/components/icons/CrossIcon.vue";
import PlusIcon from "@/components/icons/PlusIcon.vue";

export default {
	name: "ManageFilters",
	components: {
		CrossIcon,
		PlusIcon,
	},
	props: {
		filter: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showBarInput: false,
			newBarName: "",
		};
	},
	watch: {
		showBarInput: {
			immediate: true,
			handler(v) {
				if (v) {
					this.$nextTick(() => {
						this.$refs.newBarInput.focus();
					});
				} else {
					this.newBarName = "";
				}
			},
		},
	},
	methods: {
		addBar() {
			console.log("Add bar?");
			this.showBarInput = true;
		},
		submitBar() {
			// Only alphanumeric and hyphen, probably not necessary idk.
			const testRegex = /^[a-zA-Z0-9- ]+$/;
			if (!testRegex.test(this.newBarName)) {
				console.log("Invalid input!");
				return;
			}
			this.$emit("addFilterBar", this.newBarName);
			this.showBarInput = false;
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.manage-filters {
	box-shadow: 0px 4px 16px rgb(24 70 136 / 40%);

	.filter-title,
	.bar,
	.no-bars,
	.add-bar-btn,
	.add-bar-input {
		padding: 10px;
	}

	.filter-title {
		display: flex;
		color: white;
		background-color: $color-blue;
		.remove-btn {
			margin-left: auto;
		}
	}
	.bar {
		display: flex;
		align-items: center;
		border-bottom: 1px solid rgba(79, 79, 79, 0.2);
		.remove-btn {
			background-color: $color-danger;
			color: white;
			border-radius: 9999px;
			width: 14px;
			height: 14px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		.name {
			margin-left: 8px;
		}
	}
	.no-bars {
		text-align: center;
		text-transform: uppercase;
		color: $color-danger;
		padding: 20px;
		border-bottom: 1px solid rgba(79, 79, 79, 0.2);
	}
	.add-bar-btn {
		color: $color-success;
		display: flex;
		align-items: center;
		.icon {
			margin-right: 8px;
		}
	}
}
</style>
