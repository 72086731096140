<template lang="pug">
#test-view
	.page-content
		LcapChart(:metric="metric", :metric-data="data", :filters="filters")
</template>

<script>
import ApexCharts from "apexcharts";
import { database, ref, get } from "@/firebase";
import { schoolList } from "@/parse";
import LcapChart from "@/components/LcapChart.vue";

export default {
	name: "Test",
	components: {
		LcapChart,
	},
	data() {
		return {
			lineChart: null,
			barChart: null,
			metric: null,
			data: null,

			school: 0,
			timeframe: "",
			filters: null,
		};
	},
	computed: {
		isLoading() {
			return !this.data;
		},
		schoolOptions() {
			return Object.entries(schoolList).map(([id, label]) => ({
				value: id,
				label,
			}));
		},
		schoolData() {
			if (!this.data) return null;
			return this.data.data.find((d) => d.code === this.school);
		},
		// timeframeData() {
		// 	if ()
		// }
	},
	async created() {
		// Gets suspension data.
		this.data = (
			await get(ref(database, `charts/pk/-KgQj62mym0NYt6aSstR`))
		).val();
		this.metric = (
			await get(
				ref(
					database,
					`districts/pk/local/metrics/-KgQiRroDglYySwqQx33/-KgQj62mym0NYt6aSstP`
				)
			)
		).val();

		const filters = (await get(ref(database, `settings/pk/filters`))).val();
		this.filters = Object.entries(filters).map(([key, val]) => ({
			key,
			title: val.title,
			bars: Object.entries(val.bars).map(([barKey, barVal]) => ({
				key: barKey,
				title: barVal.title,
			})),
		}));

		// The filters!!! settings/pk/filters
		console.log("Got data", this.data);
	},
	methods: {
		chartClick(ev, chartContext, { seriesIndex, dataPointIndex }) {
			// The last parameter config contains additional information like `seriesIndex` and `dataPointIndex` for cartesian charts
			console.log("Chart click", ev, chartContext);
			console.log(seriesIndex, dataPointIndex);
		},
		markerClick(ev, chartContext, { seriesIndex, dataPointIndex, config }) {
			console.log("Marker click", ev, chartContext);
			console.log(seriesIndex, dataPointIndex, config);
		},
		clearChart() {
			if (this.lineChart) this.lineChart.destroy();
			if (this.barChart) this.barChart.destroy();
		},
		testBarChartData() {
			return new Array(16).fill(null).map((_, i) => ({
				x: `Category ${i}`, // Metric category
				y: Math.floor(Math.random() * 100), // Calculated percentage. num/den
			}));
		},
		testBarChartData2() {
			return new Array(16).fill(null).map((_, i) => ({
				name: `Category ${i}`,
				// data:
				x: `Category ${i}`, // Metric category
				y: Math.floor(Math.random() * 100), // Calculated percentage. num/den
			}));
		},

		renderLineChart() {
			this.clearChart();
			const options = {
				chart: {
					type: "line",
					width: "100%",
					events: {
						click: this.chartClick,
						markerClick: this.markerClick,
					},
				},
				series: [
					{
						name: "sales",
						data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
					},
				],
				xaxis: {
					categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
					title: {
						text: "This is the X axis",
					},
				},
			};
			this.lineChart = new ApexCharts(
				document.querySelector("#chart"),
				options
			);
			this.lineChart.render();
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

#test-view {
	display: flex;
	flex-direction: column;
	background: $theme-light;
	width: 100vw;
	height: 100vh;
	overflow: auto;
	.page-content {
		padding: 20px;
	}
}
</style>
