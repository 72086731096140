<template lang="pug">
.rich-text
	.modifiers
		button.edit-btn(
			:class="{ active: editor.isActive('bold') }",
			@click="editor.chain().focus().toggleBold().run()"
		)
			BoldIcon
		button.edit-btn(
			:class="{ active: editor.isActive('italic') }",
			@click="editor.chain().focus().toggleItalic().run()"
		)
			ItalicIcon
		button.edit-btn(
			:class="{ active: editor.isActive('underline') }",
			@click="editor.chain().focus().toggleUnderline().run()"
		)
			UnderlineIcon
		button.edit-btn(
			:class="{ active: editor.isActive('link') }",
			@click="setLink"
		)
			LinkIcon
	.editor-container
		EditorContent.editor(:editor="editor" :class="{ focused }")
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";

import BoldIcon from "@/components/icons/BoldIcon.vue";
import UnderlineIcon from "@/components/icons/UnderlineIcon.vue";
import ItalicIcon from "@/components/icons/ItalicIcon.vue";
import LinkIcon from "@/components/icons/LinkIcon.vue";

export default {
	name: "RichText",
	components: {
		EditorContent,
		BoldIcon,
		UnderlineIcon,
		ItalicIcon,
		LinkIcon,
	},
	props: {
		value: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			editor: null,
			focused: false,
		};
	},
	watch: {
		value(value) {
			const isSame = this.editor.getHTML() === value;
			if (isSame) {
				return;
			}
			this.editor.commands.setContent(value, false);
		},
	},
	created() {
		// TODO: Fix placeholder, not showing up for some reason.
		const Ph = Placeholder.configure({ placeholder: this.placeholder });
		this.editor = new Editor({
			content: this.value,
			extensions: [StarterKit, Underline, Link, Ph],
			onUpdate: () => {
				this.$emit("input", this.editor.getHTML());
			},
			onFocus: () => {
				this.focused = true;
			},
			onBlur: () => {
				this.focused = false;
			}
		});
	},
	beforeDestroy() {
		this.editor.destroy();
	},
	methods: {
		setLink() {
			const previousUrl = this.editor.getAttributes("link").href;
			const url = window.prompt("URL", previousUrl);
			// cancelled
			if (url === null) {
				return;
			}
			// empty
			if (url === "") {
				this.editor.chain().focus().extendMarkRange("link").unsetLink().run();
				return;
			}
			// update link
			this.editor
				.chain()
				.focus()
				.extendMarkRange("link")
				.setLink({ href: url })
				.run();
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.modifiers {
	display: flex;
	justify-content: center;
	gap: 8px;
	margin-bottom: 16px;
	.edit-btn {
		transition: all 0.1s ease;
		border-radius: 4px;
		width: 40px;
		height: 40px;
		padding: 8px;
		&.active {
			background: $color-lightblue;
			color: white;
		}
	}
}
.editor {
	outline: none;
	border-bottom: 2px solid rgba(79, 79, 79, 0.2);
	transition: border-bottom 0.2s ease;
	::v-deep {
		.ProseMirror {
			outline: none;
		}
	}
	&.focused {
		border-bottom: 2px solid $color-lightblue;
	}
}
</style>
