<template lang="pug">
MainView(:is-editing="true")
</template>

<script>
import MainView from "@/components/MainView.vue";

export default {
	name: "Edit",
	components: {
		MainView,
	},
};
</script>
