<template lang="pug">
#manage
	LcapModal.filter-info-modal(v-model="showFilterInfoModal")
		.info-modal-content
			h2.modal-header How do filters work?
			.wrapper
				.texts
					p.text Customizable filters allow your users to view data in a more digestable way. For example, if you want to highlight different performances among each grade in a particular metric, you could create a filter called “Grades” and then listing every column label which should appear when that filter is applied.
					p.text Note that it is best practice to include every possible label that would be included in the filter. In this example, the “Grades” filter might include, “Grade 9,” “Grade 10,” “Grade 11,” “Grade 12,” “Freshmen,” “Sophomores,” “Juniors,” “Seniors, “9th,” “10th,” “11th,” and “12th”. Not all of these columns will show up in every chart where the filter is applied. Instead, if this filter is applied to a chart which doesn’t have one of these bars, our filtering algorithm will simply skip over it. Filters are not case sensitive.
				ManageFilter.example-filter(:filter="dummyFilter")
	ConfirmModal(
		v-model="showRemoveFilterModal",
		:message="`Are you sure you want to delete ${tempRemoveFilter && tempRemoveFilter.title}?`",
		@confirm="removeFilter(tempRemoveFilter)"
	)
	.page-header
		.page-header-content
			LcapNav
			.tabs
				p.tab(:class="{ active: view === 'filters' }", @click="view = 'filters'") Filters
				p.tab(
					:class="{ active: view === 'notifications' }",
					@click="view = 'notifications'"
				) Notifications
	.page-content
		.filters(v-if="filters && view === 'filters'")
			.filters-info.content-header
				h1.content-title Filters
				button.question-btn(@click="showFilterInfoModal = true")
					QuestionIcon
				.spacer
				input(
					v-if="showNewFilterInput",
					ref="newFilterInput",
					v-model="tempNewFilterName",
					@keyup.enter="addFilter",
					@blur="showNewFilterInput = false"
				)
				button.new-filter-btn.btn.btn-primary(@click="showNewFilterInput = true") New Filter
			.filter-columns
				ManageFilter.filter-col(
					v-for="filter in filters",
					:filter="filter",
					@removeFilter="confirmRemoveFilter(filter)",
					@removeFilterBar="removeFilterBar(filter, $event)",
					@addFilterBar="addFilterBar(filter, $event)"
				)
		.notifications(v-else-if="view === 'notifications'")
			.content-header
				h1.content-title Notifications
			.notifications-body
				.form-row
					input(v-model="enableFeedback", type="checkbox")
					label Enable feedback form
				.form-row.contact(:class="{ inactive: !enableFeedback }")
					p.contact-submissions Email feedback form submissions to:
					input#email-to(v-model="feedbackEmailTo", type="text")
</template>

<script>
import {
	auth,
	onAuthStateChanged,
	database,
	ref,
	get,
	onValue,
	set,
	push,
	remove,
} from "@/firebase";
import LcapNav from "@/components/LcapNav.vue";
import LcapModal from "@/components/LcapModal.vue";
import ConfirmModal from "@/components/ConfirmModal.vue";
import ManageFilter from "@/components/ManageFilter.vue";
import QuestionIcon from "@/components/icons/QuestionIcon.vue";
import { isAlphaNumeric } from "@/util";
import { debounce } from "debounce";

export default {
	name: "Manage",
	components: {
		LcapNav,
		LcapModal,
		ConfirmModal,
		ManageFilter,
		QuestionIcon,
	},
	data() {
		return {
			user: null,
			showFilterInfoModal: false,
			// type: 'filters' | 'notifications'
			view: "filters",
			// Bound to settings/{district}/filters in firebase.
			filtersData: null,
			tempNewFilterName: "",
			showNewFilterInput: false,
			showRemoveFilterModal: false,
			tempRemoveFilter: null,

			notifsInitialized: false,
			feedbackEmailTo: "",
			enableFeedback: false,
			updateFeedbackEmailTo: null, // Debounced function, see created() hook.
		};
	},
	computed: {
		filters() {
			if (!this.filtersData) return null;
			return Object.entries(this.filtersData).map(([key, value]) => {
				const bars = value.bars
					? Object.entries(value.bars).map(([k, v]) => ({
							key: k,
							title: v.title,
					  })) // eslint-disable-line
					: [];
				return {
					key,
					title: value.title,
					bars,
				};
			});
		},
		dummyFilter() {
			return {
				title: "Filter Grade",
				bars: [
					{ title: "9th Grade" },
					{ title: "10th Grade" },
					{ title: "11th Grade" },
					{ title: "12th Grade" },
				],
			};
		},
	},
	created() {
		this.updateFeedbackEmailTo = debounce(
			() =>
				set(
					ref(database, `settings/pk/notifications/feedbackEmailTo`),
					this.feedbackEmailTo
				),
			1000
		);
		onAuthStateChanged(auth, (user) => {
			if (!user) {
				this.$router.push("/login");
				return;
			}
			// TODO: Figure what to do with this user.district thing. It's
			// always going to be 'pk' so why even bother getting the user?
			// Configurable LCAP -> separate firebase db's, not this multitenant
			// approach.
			get(ref(database, `users/${user.uid}`)).then((infoSnapshot) => {
				this.user = infoSnapshot.val();
				onValue(
					ref(database, `settings/${this.user.district}/filters`),
					(snapshot) => {
						this.filtersData = snapshot.val();
					}
				);

				onValue(ref(database, `settings/pk/notifications`), (snapshot) => {
					const { enableFeedback, feedbackEmailTo } = snapshot.val();
					this.enableFeedback = enableFeedback;
					this.feedbackEmailTo = feedbackEmailTo;
					this.notifsInitialized = true;
				});
			});
		});
	},
	watch: {
		filters: {
			immediate: true,
			handler(v) {
				console.log("Filters data:", v);
			},
		},
		showNewFilterInput: {
			immediate: true,
			handler(v) {
				if (v) {
					this.$nextTick(() => {
						this.$refs.newFilterInput.focus();
					});
				} else {
					this.tempNewFilterName = "";
				}
			},
		},
		enableFeedback() {
			if (!this.notifsInitialized) return;
			this.updateEnableFeedback();
		},
		feedbackEmailTo() {
			if (!this.notifsInitialized) return;
			this.updateFeedbackEmailTo();
		},
	},
	methods: {
		confirmRemoveFilter(filter) {
			this.tempRemoveFilter = filter;
			this.showRemoveFilterModal = true;
		},
		removeFilter(filter) {
			console.log("Removing filter", filter);
			remove(
				ref(database, `settings/${this.user.district}/filters/${filter.key}`)
			);
		},
		async addFilter() {
			if (!isAlphaNumeric(this.tempNewFilterName)) {
				console.log("Invalid name", this.tempNewFilterName);
				return;
			}
			console.log("Adding filter", this.tempNewFilterName);
			await push(ref(database, `settings/${this.user.district}/filters`), {
				title: this.tempNewFilterName,
			});
			this.showNewFilterInput = false;
		},
		removeFilterBar(filter, bar) {
			console.log("Removing filter bar", filter, bar);
			remove(
				ref(
					database,
					`settings/${this.user.district}/filters/${filter.key}/bars/${bar.key}`
				)
			);
		},
		addFilterBar(filter, newBarName) {
			console.log("Add bar", filter, newBarName);
			push(
				ref(
					database,
					`settings/${this.user.district}/filters/${filter.key}/bars`
				),
				{ title: newBarName }
			);
		},
		updateEnableFeedback() {
			return set(
				ref(database, `settings/pk/notifications/enableFeedback`),
				this.enableFeedback
			);
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

$content-max-width: 1200px;

// Just evenly centers content in a container.
@mixin content {
	max-width: $content-max-width;
	width: 100%;
	padding: 20px;
	margin: 0 auto;
	@include md {
		padding-left: 40px;
		padding-right: 40px;
	}
}

.info-modal-content {
	padding: 40px;
	// .modal-header
	.wrapper {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		.text {
			line-height: 140%;
		}
		.text:last-child {
			margin-top: 16px;
			// display: flex;
			// flex-direction: column
		}
		.example-filter {
			min-width: 220px;
		}
		@include md {
			flex-direction: row;
		}
	}
}

#manage {
	// max-width: 1200px;
	min-height: 100vh;
	margin: 0 auto;
	// padding: 20px;
	overflow: auto;
	display: flex;
	flex-direction: column;
	// justify-content: center;
	// align-items: center;
}

.page-header {
	background-color: $theme-light;
	.page-header-content {
		@include content;
		padding-bottom: 0px;
	}
	.tabs {
		display: flex;
		margin-top: 40px;
		.tab {
			cursor: pointer;
			padding: 10px 34px;
			font-size: 20px;
			&.active {
				background-color: white;
			}
		}
	}
}

.page-content {
	@include content;
	.content-header {
		display: flex;
		align-items: flex-end;
		border-bottom: 2px solid rgba(79, 79, 79, 0.2);
		margin-bottom: 24px;
		margin-top: 16px;
		padding: 8px 0;
		.content-title {
			text-transform: uppercase;
			font-size: 20px;
			color: rgb(79, 79, 79);
		}
	}

	.filters {
		.filters-info {
			.question-btn {
				margin-left: 16px;
			}
			.spacer {
				margin-left: auto;
			}
			.new-filter-btn {
				margin-left: 8px;
			}
		}
		.filter-columns {
			display: grid;
			gap: 24px;
			@include md {
				grid-template-columns: repeat(2, 1fr);
			}
			@include lg {
				grid-template-columns: repeat(3, 1fr);
			}
			@include xl {
				grid-template-columns: repeat(4, 1fr);
			}
			.filter-col {
				align-self: flex-start;
			}
		}
	}

	.notifications {
		.notifications-body {
			.form-row {
				display: flex;
				align-items: center;
				gap: 12px;
				&.contact {
					margin-top: 8px;
					flex-direction: column;
					align-items: flex-start;
					margin-left: 32px;
					@include sm {
						flex-direction: row;
					}
					#email-to {
						max-width: 220px;
					}
					&.inactive {
						opacity: 0.5;
					}
				}
			}
		}
	}
}
</style>
