<template lang="pug">
#upload
	.page-header
		LcapNav.nav
		h1.header-text Upload a New Metric
	.page-content
		.slide.select-priority(v-if="uploadStateName === 'select_priority'")
			h2.slide-title Select a Priority
			.select-priority-options
				button.new-priority-btn(@click="makingNewPriority = !makingNewPriority") 
					PlusIcon
					p.text New Priority
				template(v-if="makingNewPriority")
					input.new-priority(
						v-model="newPriorityName",
						ref="newPriorityInput",
						type="text",
						placeholder="New Priority Name"
					)
					.spacer
					button.next-btn.btn.btn-primary(
						v-if="newPriorityName",
						@click="uploadState += 1"
					) Next
				template(v-else)
					button.priority-btn(
						v-for="priority in priorities",
						:key="priority.key",
						@click="selectPriority(priority)"
					) {{ priority.name }}
		.slide.new-metric(v-else-if="uploadStateName === 'new_metric'")
			h2.slide-title Create a New Netric
			input(
				v-model="newMetricName",
				placeholder="Type Metric Name Here...",
				type="text"
			)
			.spacer
			button.next-btn.btn.btn-primary(
				v-if="newMetricName",
				@click="uploadState += 1"
			) Next
		.slide.upload-csv(v-else-if="uploadStateName === 'upload_csv'")
			h2.slide-title Drag and Drop your CSV file Here
			input.file-input-hidden(
				v-if="!file",
				type="file",
				placeholder="File",
				@change="onFileUpload"
			)
			p.file-name(v-if="file") {{ file.name }}
			p.text-sep OR
			button.btn.btn-primary.relative
				| Choose a file to upload.
				input.file-input-hidden(
					type="file",
					placeholder="File",
					@change="onFileUpload"
				)
			.spacer
			button.next-btn.btn.btn-primary(
				v-if="newMetricData",
				@click="uploadState += 1"
			) Next
		.slide.label-axes(v-else-if="uploadStateName === 'label_axes'")
			h2.slide-title Please Label the Axes
			.inputs
				input(v-model="xAxisLabel", type="text", placeholder="X Axis Label")
				input(v-model="yAxisLabel", type="text", placeholder="Y Axis Label")
			.spacer
			button.next-btn.btn.btn-primary(
				v-if="xAxisLabel && yAxisLabel",
				@click="uploadState += 1"
			) Next
		.slide.add-description(v-else-if="uploadStateName === 'add_description'")
			h2.slide-title Add Metric Description
			RichText.rich-text-input(
				v-model="newMetricDesc",
				:placeholder="`Type description here.`"
			)
			.spacer
			button.next-btn.btn.btn-primary(
				v-if="newMetricDesc",
				@click="uploadState += 1"
			) Next
		.slide.confirm(v-else-if="uploadStateName === 'confirm'")
			h2.slide-title Confirm Upload
			.confirm-content
				p.question Are you sure you would like this metric added to the LCAP Dashboard?
				button.btn.btn-primary(@click="uploadMetric") Yes, add new metric.
				button.btn-cancel(@click="uploadState = 1") Oops! Nevermind.
		.slide.success(v-else-if="uploadStateName === 'success'")
			h2.slide-title Metric Successfully Uploaded
			router-link(to="/")
				button.btn.btn-primary Go to metrics

	.page-footer
		.stage-indication
			.stage(v-for="i in 5", :class="{ active: i - 1 < uploadState }")
				p.number(@click="goToStage(i)") {{ i }}
				.connector
</template>

<script>
import {
	auth,
	onAuthStateChanged,
	database,
	ref,
	get,
	onValue,
} from "@/firebase";
import LcapNav from "@/components/LcapNav.vue";
import RichText from "@/components/RichText.vue";
import PlusIcon from "@/components/icons/PlusIcon.vue";
import { readCsvAsText, createChartDataModelFromCsvRows } from "@/parse";
import { uploadMetric } from "@/actions";
import { localDistrictDataToPriorities } from "@/transforms";

export default {
	name: "Upload",
	components: {
		LcapNav,
		RichText,
		PlusIcon,
	},
	data() {
		return {
			user: null,
			// Theoretical upload states:
			// 'select_priority'
			//   | 'new_metric'
			//   | 'upload_csv'
			//   | 'label_axes'
			//   | 'add_description'
			//   | 'confirm''
			//   | 'success'
			//   | 'error'
			// starts at 1
			uploadState: 1,

			// For when the user selects an existing priority.
			tempPriority: null,
			// Otherwise, state for tracking new priority.
			makingNewPriority: false,
			newPriorityName: "",

			newMetricName: "",
			xAxisLabel: "",
			yAxisLabel: "",
			newMetricDesc: null,
			file: null,
			newMetricData: null,
			userDistrictData: null,

			// Track state of in flight request.
			uploading: false,
		};
	},
	computed: {
		uploadStateName() {
			return {
				1: "select_priority",
				2: "new_metric",
				3: "upload_csv",
				4: "label_axes",
				5: "add_description",
				6: "confirm",
				7: "success",
			}[this.uploadState];
		},
		priorities() {
			if (!this.userDistrictData) return;
			return localDistrictDataToPriorities(this.userDistrictData);
		},
	},
	watch: {
		user(user) {
			if (!user) return;
			const { district } = user;
			onValue(ref(database, `districts/${district}/local`), (snapshot) => {
				this.userDistrictData = snapshot.val();
			});
		},
		makingNewPriority(v) {
			if (v) {
				this.$nextTick(() => {
					const r = this.$refs.newPriorityInput;
					r.focus();
				});
			}
		},
	},
	created() {
		onAuthStateChanged(auth, (user) => {
			if (!user) {
				this.$router.push("/login");
				return;
			}
			get(ref(database, `users/${user.uid}`)).then((infoSnapshot) => {
				this.user = infoSnapshot.val();
			});
		});
	},
	methods: {
		canGoToStage(i) {
			i;
			// return {
			// 	1:
			// }
		},
		goToStage(i) {
			// TODO: Implement canGoToStage(i) check
			if (i > this.uploadState) return;
			this.uploadState = i;
		},
		selectPriority(p) {
			this.tempPriority = p;
			this.uploadState += 1;
		},
		async onFileUpload(ev) {
			this.file = ev.target.files[0];
			console.log("Got file", this.file);
			if (
				this.file.type !== "text/csv" &&
				this.file.type !== "application/vnd.ms-excel"
			) {
				return;
			}
			try {
				const results = await readCsvAsText(this.file);
				const data = await createChartDataModelFromCsvRows(results);
				this.newMetricData = data;
			} catch (err) {
				console.log("Error", err);
			}
		},
		async uploadMetric() {
			if (this.uploading) return;
			try {
				const p = this.makingNewPriority
					? { name: this.newPriorityName }
					: this.tempPriority;
				this.uploading = true;
				await uploadMetric(
					p,
					{
						name: this.newMetricName,
						desc: this.newMetricDesc,
					},
					{
						xAxis: this.xAxisLabel,
						yAxis: this.yAxisLabel,
						data: this.newMetricData,
					},
					this.makingNewPriority
				);
				// Goes to "success"
				this.uploadState += 1;
			} catch (err) {
				console.log("Error", err);
			} finally {
				this.uploading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

#upload {
	display: flex;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
}

.page-header {
	min-height: 300px;
	padding: 40px 20px;
	background-color: $theme-light;
	.header-text {
		text-align: center;
		margin-top: 48px;
	}
	@include md {
		padding: 40px 36px;
		.nav {
			margin-left: 64px;
		}
	}
}

.page-content {
	flex: 1;
	display: flex;
	flex-direction: column;
	.slide {
		flex: 1;
		width: 100%;
		max-width: 580px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		padding: 36px 20px;
		.slide-title {
			margin-bottom: 36px;
			text-align: center;
		}
		.spacer {
			margin-top: 16px;
		}
		.next-btn {
			margin: 0 auto;
			margin-top: auto;
			padding-left: 48px;
			padding-right: 48px;
		}
	}

	.select-priority {
		.select-priority-options {
			flex: 1;
			width: 100%;
			max-width: 480px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			.new-priority-btn {
				display: flex;
				align-items: center;
				gap: 8px;
				color: $color-lightblue;
				margin-bottom: 12px;
				padding-left: 0;
				.text {
					font-size: 18px;
				}
			}
			.priority-btn {
				text-align: start;
				width: 100%;
				font-size: 18px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
				padding: 16px 0;
				transition: color 0.2s ease;
				&:hover {
					color: $color-lightblue;
				}
				&:last-child {
					border-bottom: none;
				}
			}
		}
	}

	.upload-csv {
		.text-sep {
			margin: 16px 0;
		}
	}

	.label-axes {
		.inputs {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 24px;
			@include md {
				flex-direction: row;
			}
		}
	}

	.add-description {
		.rich-text-input {
			width: 100%;
			max-width: 600px;
		}
	}

	.confirm {
		gap: 16px;
		.confirm-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 48px;
			max-width: 369px;
		}
		.question {
			text-align: center;
		}
	}
}

.page-footer {
	background-color: $theme-light;
	display: flex;
	justify-content: center;
	padding: 80px 0;
}

.stage-indication {
	display: flex;
	.stage {
		display: flex;
		align-items: center;
		color: $color-lightblue;
		.number {
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 2px solid $color-lightblue;
			border-radius: 999px;
			width: 42px;
			height: 42px;
			font-weight: 700;
			transition: all 0.2s ease;
		}
		.connector {
			border-top: 2px solid $color-lightblue;
			width: 26px;
		}
		&:last-child .connector {
			display: none;
		}
		&.active {
			color: white;
			.number {
				background-color: $color-lightblue;
			}
		}
	}
}
</style>
